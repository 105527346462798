import type { SearchState } from '@faststore/sdk'
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo, useState } from 'react'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import ProductGallery from 'src/components/sections/ProductGallery'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import { useWishlist } from 'src/hooks/wishlist'
import type {
  WishlistPageQueryQuery,
  WishlistPageQueryQueryVariables,
} from '@generated/graphql'
import CategoryBlock from 'src/components/common/CategoryBlock'
import Icon from 'src/components/ui/Icon'
import { ProductShelf } from 'src/components/sections/ProductShelf'
import Newsletter from 'src/components/sections/Newsletter'

import * as S from '../components/product/BuscaVazia/styles'

export type Props = PageProps<
  WishlistPageQueryQuery,
  WishlistPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
    location: { pathname, host },
  } = props

  const searchParams = useSearchParams(props.location)
  const { productsIds } = useWishlist()

  const title = 'Search Results | BaseStore'

  const searchTerm = useMemo(
    () => (productsIds.length ? `id:${productsIds.join(';')}` : ''),
    [productsIds]
  )

  const canonical =
    host !== undefined
      ? `https://${host}${pathname.replace(/\/$/, '')}/wishlist`
      : pathname

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
      term={searchTerm}
    >
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        language="pt-br"
        canonical={canonical}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          { position: 1, item: '/wishlist', name: 'Lista de Desejos' },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      {productsIds.length ? (
        <>
          <Breadcrumb
            breadcrumbList={[
              { position: 1, item: '/wishlist', name: 'Lista de Desejos' },
            ]}
            name="Lista de Desejos"
          />
          <ProductGallery title={title} />
        </>
      ) : (
        <>
          <S.Container containerCenter={false}>
            <Breadcrumb
              breadcrumbList={[
                { position: 1, item: '/wishlist', name: 'Lista de Desejos' },
              ]}
              name="Lista de Desejos"
            />

            <S.Content>
              <S.TitleWrapper>
                <S.Title>
                  Oops! Parece que você não tem nenhum item na sua lista
                  <Icon name="crashed-heart" width={29} height={25} />
                </S.Title>
              </S.TitleWrapper>
              <S.TextWrapper>
                <S.TextErrorMessage>
                  Mas <strong>não se preocupe</strong>! Temos certeza que o item
                  que você procura está por aqui, e vamos te ajudar a
                  encontrá-lo!
                </S.TextErrorMessage>
              </S.TextWrapper>
              <S.FavoriteMessage>
                Que tal começar pelos nossos favoritos?
              </S.FavoriteMessage>
              <S.FavoriteCategoryNav>
                <CategoryBlock id="9" small />
                <CategoryBlock id="41" small />
                <CategoryBlock id="34" small />
                <CategoryBlock id="3" small />
              </S.FavoriteCategoryNav>
            </S.Content>
          </S.Container>
          <ProductShelf
            clusterId={137}
            shelfTitle="Você também vai gostar"
            cmsId="wishlist-shelf"
            productsQuantity={8}
          />
          <Newsletter />
        </>
      )}
    </SearchProvider>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query WishlistPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)
